import React from "react"
import { Table, ListGroup } from "react-bootstrap"

export default function Contact(props) {
  function renderTelephone() {
    if (!props.contact.telephone || props.contact.telephone === "undefined")
      return
    return (
      <tr>
        <td>Telephone Number</td>
        <td style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {props.contact.telephone.map(item => {
              return (
                <ListGroup.Item>
                  <a href={"tel:" + item.replace(/-/g, "").replace(" ", "")}>
                    {item}
                  </a>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </td>
      </tr>
    )
  }

  function renderEmergencyTelephone() {
    if (!props.contact.emergency || props.contact.emergency === "undefined")
      return
    return (
      <tr>
        <td>Emergency Telephone Number</td>
        <td style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {props.contact.emergency.map(item => {
              return (
                <ListGroup.Item>
                  <a href={"tel:" + item.replace(/-/g, "").replace(" ", "")}>
                    {item}
                  </a>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </td>
      </tr>
    )
  }

  function renderWhatsapp() {
    if (!props.contact.whatsapp || props.contact.whatsapp === "undefined")
      return
    return (
      <tr>
        <td>Whatsapp Number</td>
        <td style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {props.contact.whatsapp.map(item => {
              return (
                <ListGroup.Item>
                  <a
                    href={
                      "https://wa.me/" +
                      item.replace(/-/g, "").split(" ").join("")
                    }
                  >
                    {item}
                  </a>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </td>
      </tr>
    )
  }

  function renderEmail() {
    if (!props.contact.email || props.contact.email === "undefined") return
    return (
      <tr>
        <td>Email</td>
        <td style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {props.contact.email.map(item => {
              return (
                <ListGroup.Item>
                  <a className="mr-4" href={"mailto:" + item}>
                    {item}
                  </a>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </td>
      </tr>
    )
  }

  function renderOperationHours() {
    if (
      !props.contact.operation_hours ||
      props.contact.operation_hours === "undefined"
    )
      return
    return (
      <tr>
        <td>Operation Hours</td>
        <td style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {props.contact.operation_hours.map(item => {
              return <ListGroup.Item>{item}</ListGroup.Item>
            })}
          </ListGroup>
        </td>
      </tr>
    )
  }
  return (
    <Table className="table table-bordered table-hover">
      <tbody>
        {renderTelephone()}
        {renderEmail()}
        {renderOperationHours()}
        {renderEmergencyTelephone()}
        {renderWhatsapp()}
        <tr>
          <td>Address</td>
          <td>{props.contact.address}</td>
        </tr>
      </tbody>
    </Table>
  )
}
