import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Col, Container, Navbar, Row, Button, ListGroup } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/shareButtons"
import AdBanner from "../components/adBanner"
import Contact from "../components/contact"

export default function PageDetail({ pageContext, location, data }) {
  const page = data.markdownRemark
  const { hospitalPackages } = pageContext

  function fullTitle() {
    return "List of Top Private Hospitals in Malaysia"
  }

  function seoTitle() {
    return "Top Private Hospitals in Malaysia - Contact, Visiting hours"
  }

  function seoDescription() {
    return "Top hospitals in Malaysia. Connect with best private, famous hospitals in Malaysia. Learn more about this hospital, contact number, visiting hours, visiting rules and locations guide throught Waze and Google Maps. You can also search for many other similar hospitals nearby."
  }

  function loadStates() {
    if (data.allMarkdownRemark.totalCount <= 0) return

    let stateList = data.allMarkdownRemark.edges.map(({ node }) => {
      return node.frontmatter.state
    })
    return [...new Set(stateList)].sort()
  }

  function loadHospitals(stateName) {
    if (data.allMarkdownRemark.totalCount <= 0) return

    return data.allMarkdownRemark.edges.filter(({ node }) => {
      return node.frontmatter.state === stateName
    })
  }

  function renderHospitals(stateName) {
    if (data.allMarkdownRemark.totalCount <= 0) return

    return (
      <Col xs={12} className="mb-5">
        <h5 className="text-capitalize">{stateName}</h5>
        <ListGroup as="ol" numbered>
          {loadHospitals(stateName).map(({ node }) => {
            return (
              <ListGroup.Item
                as="li"
                key={node.id}
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <Link to={node.fields.slug}>{node.frontmatter.name}</Link>
                  </div>
                  {node.frontmatter.city}
                </div>
                <div>
                  <Button
                    variant="info"
                    href={node.frontmatter.contact.waze_link}
                    target="_blank"
                    size="xs"
                  >
                    <img alt="" src="/icon_waze.png" />
                  </Button>
                  <Button
                    variant="light"
                    href={node.frontmatter.contact.gmap_link}
                    target="_blank"
                    size="xs"
                  >
                    <img alt="" src="/icon_gmap.png" />
                  </Button>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Col>
    )
  }

  const url = data.site.siteMetadata.siteUrl + location.pathname
  const twitterHandle = ""
  const tags = ""

  return (
    <Layout>
      <SEO title={seoTitle()} description={seoDescription()} />
      <Container>
        <Row>
          <Col className="mb-5">
            <Row>
              <Col xs={12}>
                <h3>{fullTitle()}</h3>
                <p>{seoDescription()}</p>
              </Col>
            </Row>
            <hr />
            <Row className="justify-content-center">
              <AdBanner />
            </Row>
            {loadStates().map(stateName => (
              <Row>{renderHospitals(stateName)}</Row>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <Navbar fixed="bottom" className="justify-content-center bg-dark">
              <h5 className="text-muted mr-sm-2">Share </h5>
              <div className="mr-auto">
                <ShareButtons
                  title={fullTitle()}
                  url={url}
                  twitterHandle={twitterHandle}
                  tags={tags}
                />
              </div>
              <Button variant="outline-primary" className="mr-sm-2" href="/">
                Home
              </Button>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "hospital" } } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            state
            city
            contact {
              waze_link
              gmap_link
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
